var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-lg-8 col-md-8 col-sm-12 card" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "full-width",
                  class: _vm.errors.has("name") ? "border-danger" : "",
                  attrs: {
                    type: "text",
                    placeholder: "Tên đầy đủ",
                    "data-vv-name": "name",
                    "data-vv-as": "Tên đầy đủ",
                  },
                  model: {
                    value: _vm.form.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name",
                  },
                }),
                _vm.errors.has("name")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("name"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "full-width",
                  class: _vm.errors.has("first_name") ? "border-danger" : "",
                  attrs: {
                    type: "text",
                    placeholder: "Tên",
                    "data-vv-name": "first_name",
                    "data-vv-as": "Tên",
                  },
                  model: {
                    value: _vm.form.first_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "first_name", $$v)
                    },
                    expression: "form.first_name",
                  },
                }),
                _vm.errors.has("first_name")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("first_name"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "full-width",
                  class: _vm.errors.has("last_name") ? "border-danger" : "",
                  attrs: {
                    type: "text",
                    placeholder: "Họ",
                    "data-vv-name": "last_name",
                    "data-vv-as": "Họ",
                  },
                  model: {
                    value: _vm.form.last_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "last_name", $$v)
                    },
                    expression: "form.last_name",
                  },
                }),
                _vm.errors.has("last_name")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("last_name"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(4),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "full-width",
                  class: _vm.errors.has("email") ? "border-danger" : "",
                  attrs: {
                    type: "text",
                    placeholder: "Email",
                    "data-vv-name": "email",
                    "data-vv-as": "Email",
                  },
                  model: {
                    value: _vm.form.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email",
                  },
                }),
                _vm.errors.has("email")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("email"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(5),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  staticClass: "full-width",
                  attrs: { type: "text", placeholder: "SĐT" },
                  model: {
                    value: _vm.form.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(6),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.form.gender,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gender", $$v)
                      },
                      expression: "form.gender",
                    },
                  },
                  [_vm._v("Nam")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "0" },
                    model: {
                      value: _vm.form.gender,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gender", $$v)
                      },
                      expression: "form.gender",
                    },
                  },
                  [_vm._v("Nữ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(7),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-date-picker", {
                  staticClass: "full-width",
                  attrs: {
                    "value-format": "yyyy/MM/dd",
                    type: "date",
                    placeholder: "Ngày sinh",
                  },
                  model: {
                    value: _vm.form.birthday,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "birthday", $$v)
                    },
                    expression: "form.birthday",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(8),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: { placeholder: "Tỉnh/TP" },
                    model: {
                      value: _vm.form.province,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "province", $$v)
                      },
                      expression: "form.province",
                    },
                  },
                  _vm._l(_vm.provinces, function (option) {
                    return _c("el-option", {
                      key: option.id,
                      attrs: { label: option.name, value: option.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(9),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: { placeholder: "Quận/huyện" },
                    model: {
                      value: _vm.form.district,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "district", $$v)
                      },
                      expression: "form.district",
                    },
                  },
                  _vm._l(_vm.districts, function (option) {
                    return _c("el-option", {
                      key: option.id,
                      attrs: { label: option.name, value: option.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(10),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: { placeholder: "Phường/xã" },
                    model: {
                      value: _vm.form.ward,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ward", $$v)
                      },
                      expression: "form.ward",
                    },
                  },
                  _vm._l(_vm.wards, function (option) {
                    return _c("el-option", {
                      key: option.id,
                      attrs: { label: option.name, value: option.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(11),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  staticClass: "full-width",
                  attrs: { type: "text", placeholder: "Địa chỉ" },
                  model: {
                    value: _vm.form.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "address", $$v)
                    },
                    expression: "form.address",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(12),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "full-width",
                    attrs: { placeholder: "Tư cách tham gia" },
                    model: {
                      value: _vm.form.team,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "team", $$v)
                      },
                      expression: "form.team",
                    },
                  },
                  _vm._l(_vm.teams, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.title, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(13),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("my-select", {
                  staticClass: "full-width",
                  attrs: { attribute: _vm.clubs, placeholder: "Chọn nhóm" },
                  model: {
                    value: _vm.form.club_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "club_id", $$v)
                    },
                    expression: "form.club_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(14),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  staticClass: "full-width",
                  attrs: { placeholder: "Mã số nhân viên/TVTC" },
                  model: {
                    value: _vm.form.user_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user_name", $$v)
                    },
                    expression: "form.user_name",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(15),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _c("el-input", {
                  staticClass: "full-width",
                  attrs: { placeholder: "CCCD/Passport" },
                  model: {
                    value: _vm.form.identity_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "identity_number", $$v)
                    },
                    expression: "form.identity_number",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12 sidebar-right" }, [
        _vm.form.dc
          ? _c("div", { staticClass: "card" }, [
              _vm._m(16),
              _c("div", { staticClass: "card-body form-card" }, [
                _c("div", [
                  _c("label", [_vm._v("Tình trạng:")]),
                  _vm.form.dc.linked_at
                    ? _c(
                        "span",
                        { staticClass: "pull-right badge badge-success" },
                        [_vm._v("Đã kết nối")]
                      )
                    : _c(
                        "span",
                        { staticClass: "pull-right badge badge-danger" },
                        [_vm._v("Chưa kết nối")]
                      ),
                ]),
                _c("div", [
                  _c("label", [_vm._v("Thời gian:")]),
                  _c("span", { staticClass: "pull-right" }, [
                    _vm._v(
                      _vm._s(_vm.$util.dateTimeFormat(_vm.form.dc.linked_at))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function ($event) {
                          return _vm.disconnectDcAccount(_vm.form.dc.user_id)
                        },
                      },
                    },
                    [_vm._v("Ngắt kết nối")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Ảnh đại diện")]),
                _c("my-image", {
                  model: {
                    value: _vm.form.avatar_image,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "avatar_image", $$v)
                    },
                    expression: "form.avatar_image",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("BIB")]),
                _c(
                  "el-input",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "length:6",
                        expression: "'length:6'",
                      },
                    ],
                    staticClass: "full-width",
                    class: _vm.errors.has("name") ? "border-danger" : "",
                    attrs: {
                      type: "number",
                      placeholder: "777777",
                      "data-vv-name": "custom_bib",
                      "data-vv-as": "Số BIB",
                    },
                    model: {
                      value: _vm.form.bib,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bib", $$v)
                      },
                      expression: "form.bib",
                    },
                  },
                  [_vm._v(">\n          ")]
                ),
                _vm.errors.has("custom_bib")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("custom_bib"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-12" }, [
        _vm.form.orders && _vm.form.orders.length
          ? _c("div", { staticClass: "card" }, [
              _vm._m(17),
              _c("div", { staticClass: "card-body form-card list-race" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _vm._m(18),
                  _c(
                    "tbody",
                    _vm._l(_vm.form.orders, function (item, index) {
                      return _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/orders/" + item.order_id } },
                              [_vm._v(_vm._s(item.order_id))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/races/" + item.race_id } },
                              [_vm._v(_vm._s(item.race_title))]
                            ),
                            _c("div", [
                              item.status
                                ? _c(
                                    "span",
                                    { staticClass: "badge badge-success" },
                                    [_vm._v("Đã thanh toán")]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "badge badge-danger" },
                                    [_vm._v("Chưa thanh toán")]
                                  ),
                            ]),
                          ],
                          1
                        ),
                        _c("td", { staticStyle: { width: "155px" } }, [
                          _vm._v(
                            _vm._s(_vm.$util.dateTimeFormat(item.created_at))
                          ),
                        ]),
                        _c("td", { staticStyle: { width: "155px" } }, [
                          item.record
                            ? _c("div", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$util.dateTimeFormat(
                                        item.record.start_at
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      _vm.$util.dateTimeFormat(
                                        item.record.end_at
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", { staticStyle: { width: "130px" } }, [
                          item.record && item.record.status == "completed"
                            ? _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "badge badge-success" },
                                  [_vm._v("Hoàn thành")]
                                ),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$util.dateTimeFormat(
                                        item.record.completed_at
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          item.status == 1 && item.record
                            ? _c("div", { staticStyle: { width: "170px" } }, [
                                item.record.is_walk
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.formatNumber(item.record.step)
                                          ) +
                                          " / " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              item.record.register_distance
                                            )
                                          ) +
                                          " bước (" +
                                          _vm._s(
                                            Math.round(
                                              (item.record.step /
                                                item.record.register_distance) *
                                                100
                                            )
                                          ) +
                                          "%)\n                  "
                                      ),
                                    ])
                                  : _c("div", [
                                      item.record.register_distance > 0
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.formatNumber(
                                                    item.record.distance
                                                  )
                                                ) +
                                                " / " +
                                                _vm._s(
                                                  _vm.formatNumber(
                                                    item.record
                                                      .register_distance
                                                  )
                                                ) +
                                                "km (" +
                                                _vm._s(
                                                  Math.round(
                                                    (item.record.distance /
                                                      item.record
                                                        .register_distance) *
                                                      100
                                                  )
                                                ) +
                                                "%)\n                    "
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.formatNumber(
                                                    item.record.distance
                                                  )
                                                ) +
                                                "km\n                    "
                                            ),
                                          ]),
                                    ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          item.status == 1
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary el-button--mini",
                                    staticStyle: { margin: "5px" },
                                    attrs: { type: "button", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.calcDistance(item)
                                      },
                                    },
                                  },
                                  [_vm._v("Tính kết quả")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-warning el-button--mini",
                                    staticStyle: { margin: "5px" },
                                    attrs: { type: "button", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addYellowCard(item)
                                      },
                                    },
                                  },
                                  [_vm._v("XÓA THẺ VÀNG")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "page-action" },
          [
            _c("el-button", { on: { click: _vm.cancel } }, [
              _vm._v("Quay lại"),
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("Cập nhật")]
            ),
          ],
          1
        ),
      ]),
      _c("loading", {
        attrs: { active: _vm.is_loading },
        on: {
          "update:active": function ($event) {
            _vm.is_loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Thông tin")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", { staticClass: "required" }, [
        _vm._v("Tên đầy đủ (nickname):"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Tên:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Họ:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Email:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("SĐT:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Giới tính:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("Ngày sinh:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("Tỉnh/TP:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-sm-2", staticStyle: { "padding-right": "0" } },
      [_c("label", [_vm._v("Quận/huyện:")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-sm-2", staticStyle: { "padding-right": "0" } },
      [_c("label", [_vm._v("Phường/xã:")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-sm-2", staticStyle: { "padding-right": "0" } },
      [_c("label", [_vm._v("Địa chỉ:")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("Tư cách tham gia:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("Nhóm:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("Mã số nhân viên/TVTC:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("label", [_vm._v("CCCD/Passport:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Daiichi Connect")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("DANH SÁCH RACE ĐĂNG KÝ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _c("th", [_vm._v("Race")]),
        _c("th", [_vm._v("Ngày đăng ký")]),
        _c("th", [_vm._v("Thời gian")]),
        _c("th", [_vm._v("Hoàn thành")]),
        _c("th", [_vm._v("Trạng thái")]),
        _c("th", [_vm._v("Hành động")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }