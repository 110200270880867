<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12 card">
      <div class="card-header">
        <h6 class="title">Thông tin</h6>
      </div>
      <div class="card-body form-card">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="required">Tên đầy đủ (nickname):</label>
          </div>
          <div class="col-sm-10">
            <el-input
              type="text"
              placeholder="Tên đầy đủ"
              class="full-width"
              v-model="form.name"
              :class="errors.has('name')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="name"
              data-vv-as="Tên đầy đủ">
            </el-input>
            <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label class="required">Tên:</label>
          </div>
          <div class="col-sm-10">
            <el-input
              type="text"
              placeholder="Tên"
              class="full-width"
              v-model="form.first_name"
              :class="errors.has('first_name')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="first_name"
              data-vv-as="Tên">
            </el-input>
            <span class="text-danger" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label class="required">Họ:</label>
          </div>
          <div class="col-sm-10">
            <el-input
              type="text"
              placeholder="Họ"
              class="full-width"
              v-model="form.last_name"
              :class="errors.has('last_name')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="last_name"
              data-vv-as="Họ">
            </el-input>
            <span class="text-danger" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label class="required">Email:</label>
          </div>
          <div class="col-sm-10">
            <el-input
              type="text"
              placeholder="Email"
              class="full-width"
              v-model="form.email"
              :class="errors.has('email')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="email"
              data-vv-as="Email">
            </el-input>
            <span class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label>SĐT:</label>
          </div>
          <div class="col-sm-10">
            <el-input
              type="text"
              placeholder="SĐT"
              class="full-width"
              v-model="form.phone">
            </el-input>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label class="required">Giới tính:</label>
          </div>
          <div class="col-sm-10">
            <el-radio v-model="form.gender" label="1">Nam</el-radio>
            <el-radio v-model="form.gender" label="0">Nữ</el-radio>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label>Ngày sinh:</label>
          </div>
          <div class="col-sm-10">
            <el-date-picker
              class="full-width"
              v-model="form.birthday"
              value-format="yyyy/MM/dd"
              type="date"
              placeholder="Ngày sinh">
            </el-date-picker>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label>Tỉnh/TP:</label>
          </div>
          <div class="col-sm-10">
            <el-select v-model="form.province" placeholder="Tỉnh/TP" class="full-width">
              <el-option
                v-for="option in provinces"
                :key="option.id"
                :label="option.name"
                :value="option.name">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2" style="padding-right: 0;">
            <label>Quận/huyện:</label>
          </div>
          <div class="col-sm-10">
            <el-select v-model="form.district" placeholder="Quận/huyện" class="full-width">
              <el-option
                v-for="option in districts"
                :key="option.id"
                :label="option.name"
                :value="option.name">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2" style="padding-right: 0;">
            <label>Phường/xã:</label>
          </div>
          <div class="col-sm-10">
            <el-select v-model="form.ward" placeholder="Phường/xã" class="full-width">
              <el-option
                v-for="option in wards"
                :key="option.id"
                :label="option.name"
                :value="option.name">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2" style="padding-right: 0;">
            <label>Địa chỉ:</label>
          </div>
          <div class="col-sm-10">
            <el-input type="text" placeholder="Địa chỉ" class="full-width" v-model="form.address"></el-input>
          </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-2">
                <label>Tư cách tham gia:</label>
            </div>
            <div class="col-sm-10">
                <el-select v-model="form.team" placeholder="Tư cách tham gia" class="full-width">
                    <el-option v-for="item in teams" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
            </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-2">
            <label>Nhóm:</label>
          </div>
          <div class="col-sm-10">
            <my-select class="full-width" :attribute="clubs" placeholder="Chọn nhóm" v-model="form.club_id"></my-select>
          </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-2">
                <label>Mã số nhân viên/TVTC:</label>
            </div>
            <div class="col-sm-10">
                <el-input placeholder="Mã số nhân viên/TVTC" class="full-width" v-model="form.user_name"></el-input>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-2">
                <label>CCCD/Passport:</label>
            </div>
            <div class="col-sm-10">
                <el-input placeholder="CCCD/Passport" class="full-width" v-model="form.identity_number"></el-input>
            </div>
        </div>
        
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">

        <div v-if="form.dc" class="card">
            <div class="card-header">
                <h6 class="title">Daiichi Connect</h6>
              </div>
            <div class="card-body form-card">

                <div>
                    <label>Tình trạng:</label>
                    <span v-if="form.dc.linked_at" class="pull-right badge badge-success">Đã kết nối</span>
                    <span v-else class="pull-right badge badge-danger">Chưa kết nối</span>
                </div>

                <div>
                    <label>Thời gian:</label>
                    <span class="pull-right">{{ $util.dateTimeFormat(form.dc.linked_at) }}</span>
                </div>

                <div class="form-group">
                    <button class="btn btn-danger" @click="disconnectDcAccount(form.dc.user_id)">Ngắt kết nối</button>
                </div>
            </div>
        </div>

      <!-- <div class="card disabled">
        <div class="card-body form-card">
          <div class="form-group" style="margin-bottom: 0;">
            <label>THẺ ĐỎ</label>
            <el-switch
              class="pull-right"
              v-model="form.is_vip"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>
          <div style="color: red;">Tài khoản bị thẻ đỏ sẽ bị loại khỏi giải.</div>
        </div>
      </div> -->

      <div class="card">
        <div class="card-body form-card">
          <div class="form-group">
            <label>Ảnh đại diện</label>
            <my-image v-model="form.avatar_image"></my-image>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body form-card">
          <div class="form-group">
            <label>BIB</label>
            <el-input
              type="number"
              placeholder="777777"
              class="full-width"
              v-model="form.bib"
              v-validate="'length:6'"
              :class="errors.has('name')?'border-danger':''"
              data-vv-name="custom_bib"
              data-vv-as="Số BIB">>
            </el-input>
            <span class="text-danger" v-if="errors.has('custom_bib')">{{ errors.first('custom_bib') }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="card">
        <div class="card-body form-card">
          <div class="form-group">
            <label>Kết nối Strava</label>
            <div v-if="form.strava_connected_at">
              <div>Lần đầu: <strong class="pull-right">{{ $util.dateTimeFormat(form.strava_first_connected_at) }}</strong></div>
              <div>Lần sau cùng: <strong class="pull-right">{{ $util.dateTimeFormat(form.strava_connected_at) }}</strong></div>
            </div>
            <div v-else>
              Chưa kết nối Strava
            </div>
          </div>
        </div>
      </div> -->

    </div>

    <div class="col-12">
      <div class="card" v-if="form.orders && form.orders.length">
        <div class="card-header">
          <h6 class="title">DANH SÁCH RACE ĐĂNG KÝ</h6>
        </div>
        <div class="card-body form-card list-race">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Race</th>
                <th>Ngày đăng ký</th>
                <th>Thời gian</th>
                <th>Hoàn thành</th>
                <th>Trạng thái</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) of form.orders">
                <td>
                  <router-link :to="'/orders/' + item.order_id">{{item.order_id}}</router-link>
                </td>

                <td>
                  <router-link :to="'/races/' + item.race_id">{{item.race_title}}</router-link>
                  <div>
                    <span v-if="item.status" class="badge badge-success">Đã thanh toán</span>
                    <span v-else class="badge badge-danger">Chưa thanh toán</span>
                  </div>
                </td>

                <td style="width: 155px;">{{ $util.dateTimeFormat(item.created_at) }}</td>
                <td style="width: 155px;">
                  <div v-if="item.record">
                    {{ $util.dateTimeFormat(item.record.start_at) }} - {{ $util.dateTimeFormat(item.record.end_at) }}
                  </div>
                </td>

                <td style="width: 130px;">
                  <div v-if="item.record && item.record.status == 'completed'">
                    <span class="badge badge-success">Hoàn thành</span>
                    <div>{{ $util.dateTimeFormat(item.record.completed_at) }}</div>
                  </div>
                </td>

                <td>
                  <div v-if="item.status == 1 && item.record" style="width: 170px;">
                    <div v-if="item.record.is_walk">
                      {{ formatNumber(item.record.step) }} / {{ formatNumber(item.record.register_distance) }} bước ({{ Math.round(item.record.step / item.record.register_distance * 100) }}%)
                    </div>
                    <div v-else>
                      <div v-if="item.record.register_distance > 0">
                        {{ formatNumber(item.record.distance) }} / {{ formatNumber(item.record.register_distance) }}km ({{ Math.round(item.record.distance / item.record.register_distance * 100) }}%)
                      </div>
                      <div v-else>
                        {{ formatNumber(item.record.distance) }}km
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <div v-if="item.status == 1">
                    <button type="button" class="btn btn-primary el-button--mini" size="small" @click="calcDistance(item)" style="margin: 5px;">Tính kết quả</button>
                    <button type="button" class="btn btn-warning el-button--mini" size="small" @click="addYellowCard(item)" style="margin: 5px;">XÓA THẺ VÀNG</button>
                    <!-- <button type="button" class="btn btn-success el-button--mini" size="small" @click="sendEmailCompleted(item)" style="margin-top: 5px;">Gửi email hoàn thành</button> -->
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>

    <loading :active.sync="is_loading"></loading>

  </div>
</template>

<script>

import { Message, Input, Button, Radio, DatePicker, MessageBox, Select, Option, Switch } from 'element-ui';
import FormCard from 'src/components/UIComponents/FormCard.vue';
import MySelect from 'src/components/UIComponents/Select'
import MyImage from 'src/components/UIComponents/Image'
import { mapState } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  components: {
    FormCard,
    ElInput: Input,
    ElButton: Button,
    ElRadio: Radio,
    ElSelect: Select,
    ElOption: Option,
    ElDatePicker: DatePicker,
    ElSwitch: Switch,
    MySelect,
    MyImage,
    Message,
    Loading
  },

  data() {
    return {
      form: {},
      is_loading: false
    }
  },

  async beforeCreate() {
    let id = this.$route.params.id;
    await this.$store.dispatch('fetchProvinces');
    await this.$store.dispatch('fetchUserDetail', id);
    await this.$store.dispatch('fetchClubs');
  },

  computed: {
    ...mapState({
      originalForm: state => state.userDetail
    }),

    provinces() {
      let data = this.$store.state.provinces;
      return data;
    },

    districts() {
      let data = this.$store.state.districts;
      return data;
    },

    wards() {
      let data = this.$store.state.wards;
      return data;
    },

    teams() {
        return [{
            title: "Cộng đồng",
            value: 'external'
        }, {
            title: 'Tư vấn viên DLVN',
            value: 'agent'
        }, {
            title: 'Nhân viên DLVN',
            value: 'staff'
        }, {
            title: 'Khách hàng',
            value: 'customer'
        }];

    },

    clubs() {
      let data = this.$store.state.clubs;
      let arr = {};
      arr.options = [{
        value: null,
        title: "Không chọn nhóm"
      }];
      for (let item of data) {
        arr.options.push({
          value: item.id - 0,
          title: item.name
        });
      }
      return arr;
    }
    
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Chỉnh sửa người dùng');
    this.$store.dispatch('setCurrentActions', [
      {
        label: 'Ngắt kết nối Strava',
        type: 'danger',
        icon: '',
        callback: this.disconnectStrava
      },
      {
        label: 'Đổi mật khẩu',
        type: 'warning',
        icon: '',
        callback: this.resetUserPassword
      },
      {
        label: 'Cập nhật',
        type: 'primary',
        icon: '',
        callback: this.save
      }
    ]);
  },

  methods: {
    async disconnectStrava() {
      MessageBox.confirm('Ngắt kết nối Strava?', 'Warning', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        type: 'warning',
        center: true
      }).then(async () => {
        this.is_loading = true;
        await this.$store.dispatch('disconnectStrava', this.form.id).then(res => {
          Message({
            message: 'Đã ngắt kết nối',
            type: 'success'
          });
        }, error => {
          Message({message: error, type: 'error'});
        });

        this.is_loading = false;

      }, error => {

      });
    },

    resetUserPassword() {
      MessageBox.prompt('Nhập mật khẩu mới', 'Đổi mật khẩu', {
        confirmButtonText: 'Đổi mật khẩu',
        cancelButtonText: 'Hủy',
        inputPlaceholder: 'Nhập mật khẩu mới',
        inputType: 'password'
      }).then(({ value }) => {
        if (value != null) {
          let id = this.$route.params.id;
          var obj = {
            id: id,
            password: value
          }
          this.$store.dispatch('updateUserPassword', obj).then(res => {
            Message({message: 'Cập nhật thành công', type: 'success'});
          }, error => {
            Message({message: error, type: 'error'});
          });
        }
      }).catch(() => {

      });
    },

    cancel() {
      this.$router.push({name: 'User'});
    },

    async addYellowCard(row) {
      var data = {
        user_id: row.user_id,
        race_id: row.race_id
      }

      let confirm = await this.confirmYellowCard();

      if (!confirm) return;

      this.is_loading = true;

      await this.$store.dispatch('addYelloCard', data).then(res => {
        Message({message: 'Thành công', type: 'success'});
      }, error => {
        Message({message: error, type: 'error'});
      });

      this.is_loading = false;
    },

    async confirmYellowCard() {
      return await MessageBox.confirm('THẺ VÀNG CHO USER', 'Warning', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        type: 'warning',
        center: true
      }).then(async () => {
        return true;
      }, error => {
        return false;
      });
    },

    async disconnectDcAccount(user_id) {
        MessageBox.confirm('Ngắt kết nối tài khoản Daiichi Connect', 'Warning', {
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy bỏ',
            type: 'warning',
            center: true
        }).then(async _ => {

            var data = {
                user_id: user_id
            }

            this.is_loading = true;

            await this.$store.dispatch('disconnectDcAccount', data).then(res => {
                Message({message: 'Đã ngắt kết nối', type: 'success'});
                this.$store.dispatch('fetchUserDetail', user_id);
            }, error => {
                Message({message: error, type: 'error'});
            });

            this.is_loading = false;

        }, error => {
            return false;
        });
    },

    async sendEmailCompleted(row) {

      var data = {
        user_id: row.user_id,
        race_id: row.race_id
      }

      this.is_loading = true;

      await this.$store.dispatch('resendEmailCompleted', data).then(res => {
        Message({message: 'Hệ thống đang xử lý', type: 'success'});
      }, error => {
        Message({message: error, type: 'error'});
      });

      this.is_loading = false;
    },

    async calcDistance(row) {
      let user_id = this.$route.params.id;
      let data = {
        user_id,
        race_id: row.race_id
      };

      this.is_loading = true;

      await this.$store.dispatch("calcDistanceUserRace", data).then(async res => {
        this.$store.dispatch('fetchUserDetail', user_id);
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
      }, error => {
        Message({message: error, type: 'error'});
      });

      this.is_loading = false;

    },

    formatNumber (num) {
      if (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      }
      return 0;
    },

    async save() {
      let self = this;
      let confirm = await this.confirmRedCard();

      if (!confirm) return;

      await this.$validator.validateAll().then(async function (result) {
        if (!result) return false;

        let data = self.form;
        delete data.orders;
        self.is_loading = true;

        await self.$store.dispatch('updateUser', self.form).then(res => {
          Message({message: 'Cập nhật thành công', type: 'success'});
        }, error => {
          Message({
            dangerouslyUseHTMLString: true,
            message: error,
            type: 'error'
          });
        });

        self.is_loading = false;

      });
    },

    async confirmRedCard() {
      if (!this.form.is_vip) return true;
      return await MessageBox.confirm('THẺ ĐỎ CHO USER', 'Warning', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        type: 'warning',
        center: true
      }).then(async () => {
        return true;
      }, error => {
        return false;
      });
    },

    async loadDistricts(province_id) {
      await this.$store.dispatch('fetchDistricts', province_id);
      if (this.form.district && this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === this.form.district);
        if (item && item.id) {
          this.$store.dispatch('fetchWards', item.id);
        }
      }
    }
  },

  watch: {
    originalForm(value) {
      this.form = Object.assign({}, value);
      console.log(this.form);
    },

    'form.gender': function(newVal, oldVal) {
      this.form.gender = newVal + "";
    },

    'form.province'(value) {
      if (this.provinces && this.provinces.length) {
        let item = this.provinces.find(x => x.name === value);
        this.loadDistricts(item.id);
      }
    },

    'form.district'(value) {
      if (this.districts && this.districts.length) {
        let item = this.districts.find(x => x.name === value);
        this.$store.dispatch('fetchWards', item.id);
      }
    }

  },

  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.$store.state.userDetail = [];
  }
}
</script>

<style lang="scss">
  .img-race {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  .order-list, .order-item, .order-item a {
    display: inline;
  }
  .navbar-nav {
    .el-button {
      margin-left: 10px !important;
    }
  }
</style>
